import React from 'react'
import './App.css'

import Redeem from './module'

function App() {
	return (
		<div className="app">
			<Redeem/>
		</div>
	)
}

export default App
