import jsCrypto from 'crypto-js'
import jsCookie from 'js-cookie'
import _ from 'lodash'
import axios from 'axios'

const _COOKIE_NAME = 'mediator_r'
const _ACCESS_TOKEN_NAME = 'mediator_a'

let url = process.env.REACT_APP_MEDIATORURI

const api = axios.create({baseURL: `${url}/api/v1`})

const collections = {
	getProfile: () => {
		const token = jsCookie.get(_ACCESS_TOKEN_NAME)

		if (_.isEmpty(token)) {
			return Promise.resolve(undefined)
		}

		api.defaults.headers.common.Authorization = `Bearer ${token}`

		return api.get('profiles')
			.then(response => {
				response.data.access_token = token
				return response.data
			})
			.catch(() => {
				return Promise.resolve(undefined)
			})
	},
	beginLogin: (clientId, redirectUri, toCheckout = false) => {
		const random = randomGen(64)
		const hash = jsCrypto.SHA256(random)
		const challenge = hash.toString(jsCrypto.enc.Base64).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
		jsCookie.set(_COOKIE_NAME, random)
		let to = `${url}/authorize?client_id=${clientId}`
		to += `&redirect_uri=${encodeURIComponent(redirectUri)}`
		to += `&response_type=code`
		to += `&code_challenge=${challenge}`
		to += `&code_challenge_method=S256`
		to += `&amsg=${encodeURIComponent('You need to login first before redeem the coupon')}`
		to += `&atype=${encodeURIComponent('info')}`

		if (toCheckout) {
			to += `&dc=1`
		}
		// console.log(url)

		window.location.href = to
	},
	fetchLatestUserDetail: () => {
		return api.get('profiles')
	},
	tokenExchange: (clientId, redirectUri) => {
		const qs = {}

		window.location.search.replace(/^\?/, '').split('&').forEach(e => {
			const query = e.split('=')
			qs[query[0]] = decodeURIComponent(query[1])
		})

		// Token exchange step
		const random = jsCookie.get(_COOKIE_NAME)
		if (_.isEmpty(_.trim(random))) {
			return Promise.reject(new Error('Random hash cannot be found!'))
		}

		return new Promise((resolve, reject) => {
			api.post('token', {
				code: qs.code,
				grant_type: 'authorization_code',
				redirect_uri: redirectUri,
				client_id: clientId,
				code_verifier: random
			})
				.then(response => {
					console.log(response.data.access_token)
					jsCookie.set(_ACCESS_TOKEN_NAME, response.data.access_token)
					api.defaults.headers.common.Authorization = `Bearer ${jsCookie.get(_ACCESS_TOKEN_NAME)}`

					return api.get('profiles')
				})
				.then(response => {
					response.data.access_token = jsCookie.get(_ACCESS_TOKEN_NAME)
					resolve(response.data)
				})
				.catch(err => {
					reject(err)
				})
				.finally(() => {
					jsCookie.remove(_COOKIE_NAME)
				})
		})
	},
	beginLogout: (clientId) => {
		return new Promise(resolve => {
			api.defaults.headers.common.Authorization = undefined
			jsCookie.remove(_ACCESS_TOKEN_NAME)
			resolve()

			setTimeout(() => {
				window.location.href = `${url}/logout?client_id=${clientId}`
			}, 300)
		})
	},
	getAccountURL: (clientId, auto = false) => {
		const accountUrl = `${url}/account?client_id${clientId}`
		if (auto) {
			window.location.href = accountUrl
		} else {
			return accountUrl
		}
	}
}

export const {
	getProfile,
	beginLogin,
	tokenExchange,
	beginLogout,
	getAccountURL

} = collections


function randomGen(length) {
	let result = ''
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	const charactersLength = characters.length
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}
