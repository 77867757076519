import axios from 'axios'
import Cookies from 'js-cookie'

const _ACCESS_TOKEN_NAME = 'mediator_a'

const mediatorInstance = axios.create({
	baseURL: `${process.env.REACT_APP_MEDIATORURI}/api/v1`,
})
const ssoAPIInstance = axios.create({baseURL: process.env.REACT_APP_SSOURI})

const collections = {
	verifyRedeem: async (clientId, coupon) => {
		const token = Cookies.get(_ACCESS_TOKEN_NAME)
		mediatorInstance.defaults.headers.common.authorization = `Bearer ${token}`

		const response = await mediatorInstance.post(`/subscriptor`, {client_id: clientId, token})
		const {utoken, sso_key} = response.data

		ssoAPIInstance.defaults.headers.common.authorization = `Bearer ${utoken}`
		ssoAPIInstance.defaults.headers.common['x-api-key'] = sso_key

		return ssoAPIInstance.post(`/coupon`, {coupon})
	},
	beginPaymentInitial: async (couponId) => {
		return ssoAPIInstance.post(`/redeem/payment/initial`, {couponId})
	}
}

export const {
	verifyRedeem,
	beginPaymentInitial
} = collections
