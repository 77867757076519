import React, {useEffect, useState} from 'react'
import _ from 'lodash'

import Tonton from './tonton'
import {beginLogin, beginLogout, getProfile, tokenExchange} from '../lib/authentication'
import {verifyRedeem, beginPaymentInitial} from '../lib/request'

export default function Redeem(props) {

	const [errMsg, setErrMsg] = useState('')
	const [clientId, setClientId] = useState(null)
	const [myProfile, setMyProfile] = useState({})
	const [onLogoutState, setOnLogoutState] = useState(false)
	const [form, setForm] = useState({redeemCode: '', captcha: ''})
	const [errMsgInternal, setErrMsgInternal] = useState('')
	const [succesMsg, setSuccesMsg] = useState('')

	useEffect(() => {
		let cid = null

		const possibleClientId = window.location.pathname.replace(/^\//, '')
		const hostname = window.location.hostname
		if (possibleClientId === 'redeem' || (/^redeem\./.test(hostname) && /revid\.my$/.test(hostname))) {
			cid = process.env.REACT_APP_TONTON_CLIENTID
		} else {
			if (/^\d*$/.test(possibleClientId)) {
				cid = possibleClientId
			}
		}

		let qss = {}
		window.location.search.replace(/^\?/, '').split('&').forEach(q => {
			const qsplit = q.split('=')
			qss[qsplit[0]] = decodeURIComponent(qsplit[1])
		})

		if (_.isNull(cid) && _.has(qss, 'client_id')) {
			cid = qss.client_id
		}

		if (_.isNull(cid)) {
			setErrMsg('Unknown or invalid application. Check integration settings')
		} else if (!_.isEmpty(cid.toString())) {
			setClientId(cid)

			const redirectUri = window.location.href.replace(window.location.search, '')

			if (_.has(qss, 'code')) {
				tokenExchange(cid, redirectUri)
					.then(profile => {
						setMyProfile(profile)
						window.history.replaceState(null, '', redirectUri)
					})
					.catch(err => {
						if (err.toString().match(/Random hash cannot be found/)) {
							beginLogin(cid, redirectUri)
						} else {
							console.error(err)
						}
					})
			} else {
				getProfile()
					.then(profile => {
						if (profile === undefined) {
							if (_.has(qss, 'wait') || _.has(qss, 'logout')) {
								// Do nothing
								console.log(redirectUri)
							} else {
								beginLogin(cid, redirectUri)
							}
						} else {
							setMyProfile(profile)
						}
					})
					.catch(err => {
						console.error(err.toString())
					})
			}

		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function manualLogin () {
		setOnLogoutState(false)
		beginLogin(clientId, window.location.href.replace(window.location.search, ''))
	}

	function logout () {
		beginLogout()
			.then(() => {
				const redirectUri = window.location.href.replace(window.location.search, '')
				setMyProfile({})
				window.history.replaceState(null, '', `${redirectUri}?logout`)
				setOnLogoutState(true)
			})
			.catch(() => {})
	}

	function submit (e) {
		e.preventDefault()
		e.stopPropagation()

		if (_.isEmpty(_.trim(form.redeemCode))) {
			return setErrMsgInternal('Promotion code is required')
		}

		// @todo ZIE to implement something here?
		if (_.isEmpty(_.trim(form.captcha))) {
			return setErrMsgInternal('Please complete captcha verification')
		}

		// ada captcha tapi tak verify... haha
		verifyRedeem(clientId, form.redeemCode)
			.then(response => {
				const { couponId, planId, valid } = response.data
				if (_.isEmpty(_.trim(`${planId}`)) || !valid || _.isEmpty(_.trim(couponId))) {
					return setErrMsgInternal('Coupon is not valid')
				}
				return beginPaymentInitial(couponId)
					.then(response => {
						// console.log(response.data)
						if (response.data.success) {
							setSuccesMsg('Your code was successfully redeemed. Your plan has been updated. Enjoy your watching. Thank you!')
						}
					})
			})
			.catch(err => {
				if (err.response) {
					const {message, msg} = err.response.data
					if (!_.isEmpty(_.trim(message))) {
						setErrMsgInternal(message)
					} else if (!_.isEmpty(_.trim(msg))) {
						setErrMsgInternal(msg)
					} else {
						setErrMsgInternal('Coupon is not valid')
					}
				} else {
					console.error(err)
				}
			})
	}

	function changeForm (key, value) {
		setErrMsgInternal('')
		setSuccesMsg('')
		const formClone = _.cloneDeep(form)
		formClone[key] = value
		setForm(formClone)
	}

	const toPass = {
		form,
		myProfile,
		onLogoutState,
		logout,
		manualLogin,
		changeForm,
		submit,
		succesMsg,
		errMsg: errMsgInternal
	}

	let template = <div className="alert alert-danger" role="alert">Unknown or invalid application. Check integration settings</div>
	if (clientId === process.env.REACT_APP_TONTON_CLIENTID) {
		template = <Tonton {...toPass} />
	}

	return (
		<>
			{
				!_.isEmpty(_.trim(errMsg)) && (
					<div className="alert alert-danger" role="alert">{errMsg}</div>
				)
			}
			{
				_.isEmpty(_.trim(errMsg)) && (
					<>{template}</>
				)
			}

		</>
	)
}
