import React from 'react'
import _ from 'lodash'
import ReCAPTCHA from 'react-google-recaptcha'
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Alert
} from 'react-bootstrap'

import './tonton.css'

export default function Tonton (props) {

	const { myProfile, logout, form, changeForm, submit, errMsg, succesMsg } = props

	return (
		<div className={'tonton'}>
			<div className="menu-bar-container sideMenuOpen">
				<div>
					<div className="menu-bar standard show d-flex">
						<div className="left-section d-flex align-items-center m-0">
							<div className="logo ">
								<a href="https://watch.tonton.com.my">
									<img
										src="https://headend-api.tonton.com.my/v100/imageHelper.php?id=0:377:STATIC:SERVICE_LOGO_default:png&h=80&appID=TONTON"
										alt="tonton"
										className="w-100"/>
								</a>
							</div>
							<div className="d-lg-block d-none">
								<div className="menu-item  HOME">
									<a href="https://watch.tonton.com.my">Home</a>
								</div>
								<div className="menu-item LIVE">
									<a href="https://watch.tonton.com.my/#/live/tv3">Live TV</a>
								</div>
								<div className="menu-item SERIES">
									<a href="https://watch.tonton.com.my/#/series">TV Shows</a>
								</div>
								<div className="menu-item MOVIES">
									<a href="https://watch.tonton.com.my/#/Cinema">Cinema</a>
								</div>
								<div className="menu-item EVENTS ">
									<a href="https://watch.tonton.com.my/Events">Events</a>
								</div>
							</div>

						</div>
						<div className="right-section ">
							<div className="menu-item-lo">
								<h5 id="welcome">Hi {!_.isEmpty(myProfile) ? _.isEmpty(_.trim(myProfile.name)) ? myProfile.email : myProfile.name : 'Guest'}</h5>
								{
									!_.isEmpty(myProfile) && (
										<button className="ml-4 text-center btn btn-tonton py-1" onClick={logout}>
											Logout
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className="tontonbg">
				<Container>
					<Row className="align-items-center twrap">
						<Col xs={7} md={4} className="mb-4">
							<img
								src="https://headend-api.tonton.com.my/v100/imageHelper.php?id=0:377:STATIC:SERVICE_LOGO_default:png&h=80&appID=TONTON"
								alt="tonton"
								className="w-100"/>
						</Col>

						<Col lg={12} className="mb-4">
							<h1>Redeem Your Gift</h1>
							<h4>Enter your code below to get started.</h4>
						</Col>
						<Col xs={6} md={5}>
							<Form noValidate id="redeemForm" onSubmit={submit}>
								<Form.Group className="input-group input-group-lg mb-3">
									<Form.Control
										type="text"
										className="form-control-lg"
										onChange={e => changeForm('redeemCode', e.target.value)}
										placeholder="Promotion Code"
									/>
									<Form.Control.Feedback type="invalid">
										Please Insert Your Redeem Code
									</Form.Control.Feedback>
								</Form.Group>
								{
									!_.isEmpty(_.trim(form.redeemCode)) && (
										<ReCAPTCHA
											id="recaptcha"
											name="captcha"
											className="mb-4"
											onChange={v => changeForm('captcha', v)}
											sitekey={process.env.REACT_APP_CAPTCHA_KEY}
										/>
									)
								}
								<Button
									type="submit"
									variant=""
									className="btn-tonton"
									size="lg"
									disabled={_.isEmpty(myProfile)}
									onClick={submit}>
									Redeem
								</Button>

								{
									!_.isEmpty(errMsg) && (
								      <Alert variant="danger" className="my-4">{errMsg}</Alert>
								    )
								}
								{
									!_.isEmpty(succesMsg) && (
								       <Alert variant="success" className="my-4">{succesMsg}</Alert>
								    )
								}
							</Form>
						</Col>
						<Col xs={12} md={12}>
							<h6 className='mt-4 text-capitalize'>No promotion code? <a href="https://www.tonton.com.my/tontonup/index.html"> Click here to purchase a subscription</a></h6>
						</Col>
					</Row>
				</Container>
			</section>
			<footer>
				<div className="copyright-tonton">
					<div className="powered-by dZDpRR">Powered by
						<div className="rev-id"></div>
						© Copyright {new Date().getFullYear()} - REV.ID by REV Media Group
						<br/>
						A part of Media Prima Group
					</div>
				</div>
			</footer>
		</div>

	)
}
